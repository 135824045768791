<div class="chess-container">
    <div id ="render-container" [ngClass]="{'normal-container':!isMobile, 'mobile-container':isMobile }">
        <progress id ="progress-bar"></progress>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf = "displayWarning === true">
        {{warningMsg}}
    </div>
    <button class="chess-menu-button" (click) ="onMenuClick()" *ngIf ="showMenuButton"  style="background: url('../../../../../assets/chess/play-button.png'); background-size:cover"></button>
    
    <svg  *ngIf = "heavyProcessing === true" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform 
            attributeName="transform" 
            attributeType="XML" 
            type="rotate"
            dur="1s" 
            from="0 50 50"
            to="360 50 50" 
            repeatCount="indefinite" />
        </path>
    </svg>
    <div class="chess-menu"  *ngIf ="menuOpened">
        <!--<div class="menu-section">
            <p class ="choice-title">{{aiTypeLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click) ="aiChoices.previousChoice()"></button>
                <p>{{aiChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click) ="aiChoices.nextChoice()"></button>
            </div>
        </div>
        <div class="menu-section">
            <p class ="choice-title">{{difficultyLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click) ="difficultyChoices.previousChoice()"></button>
                <p>{{difficultyChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click) ="difficultyChoices.nextChoice()"></button>
            </div>
        </div>
        -->
        <div class="menu-section">
            <p class ="choice-title">{{playerTypeLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click)="onPlayerTypePrevChoice()"></button>
                <p>{{playerTypesChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click)="onPlayerTypeNextChoice()"></button>
            </div>
        </div>
        <div class="menu-section">
            <p class ="choice-title">{{playerColorLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click)="onPlayerColorPrevChoice()"></button>
                <p>{{playerColorChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click)="onPlayerColorNextChoice()"></button>
            </div>
        </div>
        <div class="menu-section">
            <p class ="choice-title">{{viewLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click) ="viewChoices.previousChoice()"></button>
                <p>{{viewChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click) ="viewChoices.previousChoice()"></button>
            </div>
        </div>
        <div class="menu-section">
            <p class ="choice-title">{{soundLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click) ="onSoundPrevChoice()"></button>
                <p>{{soundChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click) ="onSoundNextChoice()"></button>
            </div>
        </div>
        <div class="menu-section">
            <p class ="choice-title">{{soundEffectLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click) ="onSoundEffectPrevChoice()"></button>
                <p>{{soundEffectChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click) ="onSoundEffectNextChoice()"></button>
            </div>
        </div>
        <div class="menu-section">
            <p class ="choice-title">{{searchDepthLabel}}</p>
            <div class="choice-container">
                <button class="section-button" style="background: url('../../../../../assets/chess/previous-button.png'); background-size:cover" (click) =" onDepthPrevChoice()"></button>
                <p>{{searchDepthChoices.getCurrenthoice()}}</p>
                <button class="section-button" style="background: url('../../../../../assets/chess/next-button.png'); background-size:cover" (click) ="onDepthNextChoice()"></button>
            </div>
        </div>
        <button id="play-button" type="button" class="btn btn-danger" (click)="startGame()">{{playLabel}}</button>
    </div>
    <div class="credits-container" *ngIf="showCredits">
        <p>Music: Juan-Sanchez-Now-The-Silence</p>
        <p>Chess Model: https://sketchfab.com/robie1/collections/low-poly-chess-set</p>
    </div>
    <div class="pawn-promotion-container" *ngIf="showPawnPromotionDialog">
        <strong>{{pawnPromotionQuestion}}</strong>
        <div class="form-check">
           <input class="form-check-input" type="radio" name="promotion-choice" id="queen-radio" [value]="1" [(ngModel)]="pawnPromotionChoice" checked>
           <label class="form-check-label" for="queen-radio">
           {{queen}}
           </label>
        </div>
        <div class="form-check">
           <input class="form-check-input" type="radio" name="promotion-choice" id="bishop-radio" [value]="4" [(ngModel)]="pawnPromotionChoice">
           <label class="form-check-label" for="bishop-radio">
            {{bishop}}
           </label>
        </div>
        <div class="form-check">
           <input class="form-check-input" type="radio" name="promotion-choice" id="rook-radio" [value]="3" [(ngModel)]="pawnPromotionChoice">
           <label class="form-check-label" for="rook-radio">
            {{rook}}
           </label>
        </div>
        <div class="form-check">
           <input class="form-check-input" type="radio" name="promotion-choice" id="knight-radio" [value]="5" [(ngModel)]="pawnPromotionChoice">
           <label class="form-check-label" for="knight-radio">
            {{knight}}
           </label>
        </div>
        <div class="button-container">
            <button id="promotion-button" type="button" class="btn btn-success" (click)="submitPromotionChoice()">OK</button>
        </div>
    </div>
</div>