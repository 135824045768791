<div id = "sokoban-container">
    <div id="row1">
        <iframe id="video-player" src="https://www.youtube.com/embed/AdncpGghQCQ" title="YouTube video player"
     frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
    </iframe>
    </div>
    <div id="row2">
        <p *ngFor="let item of descriptionParagraphs" class="text-light"> 
            {{item}}
        </p>
    </div>
</div>
  
  