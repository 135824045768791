export const STONE_INIT_Z = 0;
export const STONE_INIT_Y = 0;
export const STONE_INIT_X = 520;
export const FIRST_LINE_X = 330;
export const SECOND_LINE_X = -265;
export const GOAL_CENTER_X = -450;
export const GOAL_RADIUS = 57;
export const RUNWAY_WIDTH = 156;
export const ACCELERATION = -450;
// x: 330 1ere ligne
// x : -265 2e ligne
// x: -450 centre cible
// 50 rayon du cercle
