<div id = 'render-container'>
  <progress id ="progress-bar"></progress>
  <div id = 'curling-logo-container' *ngIf="hideLogo === false && hideHud===false">
    <p>Virtual - Curling</p>
  </div>
  <div id = 'game-option-container'>
    <div class ='vertical-menu' id='start-menu' *ngIf="startMenu===true && hideHud===false">
        <button mat-button (click)="startButtonClick()"> {{startLabel}}</button>
        <button mat-button (click) = "chooseInfos()"> {{infosLabel}}</button>
        <div class ='horizontal-menu' id='option-menu'>
            <p>{{soundLabel}}</p>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="language" id="frRadio" (change) = "playSound()" value="option1">
              <label class="form-check-label text-dark" for="onRadio">
                ON
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="language" id="engRadio"  (change) = "stopSound()" value="option2" checked>
              <label class="form-check-label text-dark" for="offgRadio">
                OFF
              </label>
            </div>
        </div>
    </div>
    <div *ngIf="infosChoiceMade" id = 'infos-div'>
       <div id = 'infos-text'>{{infosDDescriptionLabel}}</div>
       <button  mat-button (click) = "cancelInfosDisplay()">
        {{infosLabelBackLabel}}
       </button>
    </div>
    <div class ='vertical-menu' id='player-menu' *ngIf="((infosChoiceMade ===false) && (startMenu===false) && (playerChoiceMade === false) && (hideHud===false))">
          <button mat-button  (click) = "playerChoice(1)"> {{player1Label}}</button>
          <button mat-button (click) = "playerChoice(2)">{{player2Label}}</button>
    </div>
    <div class ='vertical-menu' id='player-difficulty-menu' *ngIf="((startMenu===false) && (onePlayer === true) && (playerChoiceMade === true) && (difficultyChoiceMade===false) &&(hideHud===false))">
          <button mat-button (click) ="difficultyChoice(1)"> {{easyLabel}}</button>
          <button mat-button (click) ="difficultyChoice(2)"> {{mediumLabel}}</button>
          <button mat-button (click) ="difficultyChoice(3)"> {{hardLabel}}</button>
    </div>
  </div>
  <div class="progress" id ='shooting-progress-div'>
    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" id='real-progress-div'>
    </div>
  </div>
  <div id ='game-info-container' *ngIf="gameStarted===true">
    <div id = 'player1-infos' >
        <div id = 'player1-stones-indicator'  *ngFor="let item of this.game.GetPlayer1().getRemainingNbStones()">
          <img src = "../../../assets/stone2d2.png">
        </div>
    </div>
    <div id = 'round-div' class = 'text-light'>{{getStep()}}</div>
    <div id = 'player-div'>{{game.getCurrentPlayerName()}}</div>
    <div id = 'player2-infos'>
        <div id = 'player2-stones-indicator' *ngFor="let item of this.game.GetPlayer2().getRemainingNbStones()">
          <img src = "../../../assets/stone2d3.png">
        </div>
    </div>
  </div>
</div>
