<div class='header-container'>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="#" id ="name">Boladji VINOU</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>    
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class = "navbar-nav ml-auto">
          <li class="nav-item mr-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="language" id="frRadio" (change) = "frenchButtonChange($event)" value="option1"  checked>
              <label class="form-check-label text-light" for="frRadio">
                Fr
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="language" id="engRadio"  (change) = "englishButtonChange($event)" value="option2">
              <label class="form-check-label text-light" for="engRadio">
                Eng
              </label>
            </div>
          </li>
          <li class="nav-item mr-4"><a routerLink="portfolio" class="text-light">PortFolio</a></li>
          <li class="nav-item mr-4"><a routerLink="experience" class="text-light">{{workingExperience}}</a></li>
          <li class="nav-item mr-4"><a routerLink="contact" class="text-light">Contact</a></li>
        </ul>
      </div>
    </nav>
  </header>
</div>
<main>
  <article>
      <div class = "background-container">
        <video preload autoplay [muted]="true" loop class="frontground-video" id = 'background-vid1' oncanplay="this.play()" onloadedmetadata="this.muted = true" playsinline>
          <source src="../assets/itspecialist.webm" type="video/webm">
            <p>Oops you can't see my background video :(</p>
        </video>
      </div>
      <div class = "children-container">
          <router-outlet></router-outlet>  
      </div>
  </article>
</main>
<footer class="footer">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <ul>
      <li class="mr-2"><a href="https://github.com/boladjiVinou" target="_blank"  class="text-light">Github</a></li>
      <li class="mr-2"><a href="https://leetcode.com/boladjivinou/" target="_blank"  class="text-light">LeetCode</a></li>
      <li class="mr-2"><a href="https://www.hackerrank.com/boladjiVinou?hr_r=1" target="_blank"  class="text-light">HackerRank</a></li>
    </ul>
  </nav>
</footer>