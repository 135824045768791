import { ChessNodeState } from './chessnode';

export class ChessGameState
{
    private readonly nodeStates: ChessNodeState[];
    private readonly blackDrawCount;
    private readonly whiteDrawCount;
    constructor(inNodeStates: ChessNodeState[], inBlackDrawCount: number, inWhiteDrawCount: number)
    {
        this.nodeStates = inNodeStates;
        this.blackDrawCount = inBlackDrawCount;
        this.whiteDrawCount = inWhiteDrawCount;
    }
    getNodesStates(): ChessNodeState[]
    {
        return this.nodeStates;
    }
    getBlackDrawCount(): number
    {
        return this.blackDrawCount;
    }
    getWhiteDrawCount(): number
    {
        return this.whiteDrawCount;
    }
}
