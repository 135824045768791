export enum BinaryPieceType
{
    Black = 0,
    White,
    Pawn,
    Bishop,
    Rook,
    Knight,
    Queen,
    King
}
