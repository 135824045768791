<div class ='card-container'>
    <div class="card">
        <img class="card-img-top" src="../../assets/gmail.png" title="Icon made by Freepik from Flaticon.com, Creative Commons by 3.0">
        <div class="card-body">
            <h5 class="card-title text-light">Email</h5>
            <p class="card-text text-light">boladjivinou@gmail.com</p>
        </div>
    </div>
    <div class="card" >
        <img class="card-img-top"  src="../../assets/linkedin.png" title="Icon made by Freepik from Flaticon.com, Creative Commons by 3.0">
        <div class="card-body">
            <h5 class="card-title text-light">LinkedIn</h5>
            <p class="card-text text-light"><a href="https://www.linkedin.com/in/boladji-vinou-8a67b6128" target="_blank">
                {{myProfil}}
              </a></p>
        </div>
    </div>
</div>
  